import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { DefaultPageContainer as PageContainer } from "@layout";
import { SearchControl, SearchResult } from "@molecules";
import algoliasearch from "algoliasearch";
import { Container } from "@atoms";
import { t } from "@utils";
import { useAppState } from "@state/state";
import { navigate } from "gatsby";

const SearchPage = ({ location, pageContext }) => {
  const [query, setQuery] = useState();
  const [results, setResults] = useState();
  const [{ translation }, dispatch] = useAppState();
  const { language } = pageContext;
  const { lang } = translation;

  const siteClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_SITE_APP_ID,
    process.env.GATSBY_ALGOLIA_SITE_SEARCH_KEY
  );
  const siteIndex = siteClient.initIndex(process.env.GATSBY_ALGOLIA_SITE_INDEX);

  useEffect(() => {
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const newQuery = urlParams.get("q");
    if (newQuery) {
      setQuery(newQuery);
    }
  }, [location]);

  // perform a new search when query changes
  useEffect(() => {
    if (query?.length) {
      // search pages
      siteIndex
        .search(query, { filters: `lang:${lang}` })
        .then(({ hits }) => {
          setResults(
            previousResult => ({ ...previousResult, pages: hits }.pages)
          );
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (lang) {
      if (lang !== language) {
        let url = "";
        if (language === "zh") url += process.env.GATSBY_SITE_URL;
        if (lang === "zh") url += process.env.GATSBY_ZH_SITE_URL;
        navigate(`${url}${lang === "en" ? "" : `/${lang}`}/search`);
      }
    } else {
      dispatch({
        type: "setLang",
        lang: language,
      });
    }
  }, [lang]);

  return (
    <PageContainer pageContext={pageContext}>
      <SearchControl
        queryState={[query, setQuery]}
        resultsCount={results?.length}
      />
      <div className="w-full py-0 sm:py-6 sm:py-12">
        <Container>
          <div>
            {/* query and results */}
            {!!query?.length && !!results?.length && (
              <ul>
                {results?.map((result, i) => (
                  <li
                    className={classNames("my-6", {
                      "pt-6 border-t border-silver-light": i !== 0,
                    })}
                  >
                    <SearchResult {...result} />
                  </li>
                ))}
              </ul>
            )}
            {/* query and no results */}
            {!!query?.length && !results?.length && (
              <div className="text-xl text-black py-12 lg:py-20 text-center">
                {t("No results were returned by your query.", lang)}
              </div>
            )}
            {/* no query */}
            {!query?.length && (
              <div className="text-xl text-black py-12 lg:py-20 text-center">
                {t("Enter a query above to search.", lang)}
              </div>
            )}
          </div>
        </Container>
      </div>
    </PageContainer>
  );
};

SearchPage.defaultProps = {};

SearchPage.propTypes = {};

export default SearchPage;
