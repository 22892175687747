/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import propTypes from "prop-types";
import classNames from "classnames";
import { Meta } from "@atoms";
import loadable from "@loadable/component";

const Footer = loadable(() => import("@molecules"), {
  resolveComponent: components => components.Footer,
});

const PageContainer = ({
  children,
  className,
  isAction,
  path,
  meta,
  pageContext,
  ...rest
}) => {
  const { language: lang } = pageContext;
  return (
    <>
      <div
        className={classNames(
          className,
          "flex flex-grow flex-col min-h-screen w-full box-border overflow-x-hidden"
        )}
      >
        <main>{children}</main>
        <Footer lang={lang} />
      </div>
      {meta && <Meta {...meta} />}
    </>
  );
};

PageContainer.defaultProps = {
  className: "",
};

PageContainer.propTypes = {
  className: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default PageContainer;
